import { vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8c0d9ad6"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["placeholder", "disabled"]
const _hoisted_2 = ["type", "placeholder", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_editor = _resolveComponent("vue-editor")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["Input", {'hasError':_ctx.error}])
  }, [
    (_ctx.type == 'textarea' && !_ctx.withStyles)
      ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
          key: 0,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          placeholder: _ctx.placeholder,
          disabled: _ctx.disabled,
          onKeyup: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hasErrors())),
          onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('data-update')))
        }, null, 40, _hoisted_1)), [
          [_vModelText, _ctx.value]
        ])
      : _createCommentVNode("", true),
    (_ctx.type != 'textarea' && !_ctx.withStyles)
      ? _withDirectives((_openBlock(), _createElementBlock("input", {
          key: 1,
          type: _ctx.type,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value) = $event)),
          autocomplete: "on",
          placeholder: _ctx.placeholder,
          disabled: _ctx.disabled,
          onKeyup: _cache[4] || (_cache[4] = ($event: any) => (_ctx.hasErrors())),
          onInput: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('data-update')))
        }, null, 40, _hoisted_2)), [
          [_vModelDynamic, _ctx.value]
        ])
      : _createCommentVNode("", true),
    (_ctx.withStyles)
      ? (_openBlock(), _createBlock(_component_vue_editor, {
          key: 2,
          class: "vue-editor",
          modelValue: _ctx.value,
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.value) = $event)),
          editorToolbar: _ctx.customToolbar
        }, null, 8, ["modelValue", "editorToolbar"]))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("label", null, _toDisplayString(_ctx.label), 513), [
      [_vShow, _ctx.label]
    ]),
    _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.errorMessage), 513), [
      [_vShow, _ctx.error]
    ])
  ], 2))
}